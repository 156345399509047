




/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from "@vue/composition-api";
import { skeletonVariants } from "@/lib/pfg/components/skeleton";

export default defineComponent<any, any, any, any, any, any, any, any, any>({
	setup() {
		return { skeletonVariants };
	},
});
