




/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from "@vue/composition-api";
import { textareaVariants } from "@/lib/pfg/components/textarea";

export default defineComponent<any, any, any, any, any, any, any, any, any>({
	setup() {
		return { textareaVariants };
	},
});
