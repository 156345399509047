import { cva } from "@/lib/style/cva.config";

export const badgeVariants = cva({
	base: "inline-flex items-center rounded-full border border-gray-200 px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 dark:border-gray-800 dark:focus:ring-gray-300",
	variants: {
		variant: {
			default:
				"border-transparent bg-gray-900 text-gray-50 hover:bg-opacity-80 hover:bg-gray-900 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-opacity-80 dark:hover:bg-gray-50",
			secondary:
				"border-transparent bg-gray-100 text-gray-900 hover:bg-gray-100 hover:bg-opacity-80 dark:bg-gray-800 dark:text-gray-50 dark:hover:bg-gray-800 dark:hover:bg-opacity-80",
			destructive:
				"border-transparent bg-red-500 text-gray-50 hover:bg-red-500 hover:bg-opacity-80 dark:bg-red-900 dark:text-gray-50 dark:hover:bg-red-900 dark:hover:bg-opacity-80",
			outline: "text-gray-950 dark:text-gray-50",
		},
	},
	defaultVariants: {
		variant: "default",
	},
});
