











/* eslint-disable @typescript-eslint/no-explicit-any */
import { type PropType, defineComponent } from "@vue/composition-api";
import { badgeVariants } from "@/lib/pfg/components/badge";
import type { VariantProps } from "@/lib/style/cva.config";

export default defineComponent<any, any, any, any, any, any, any, any, any>({
	props: {
		variant: {
			type: String as PropType<VariantProps<typeof badgeVariants>["variant"]>,
			default: "default",
		},
	},
	setup() {
		return { badgeVariants };
	},
});
