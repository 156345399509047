import { cva } from "@/lib/style/cva.config";

export const buttonVariants = cva({
	base: "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-900 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
	variants: {
		variant: {
			default: "bg-gray-900 text-gray-50 hover:bg-gray-900 hover:bg-opacity-90",
			destructive: "bg-red-500 text-gray-50 hover:bg-opacity-90 hover:bg-red-500",
			outline: "border border-gray-200 border-solid bg-white hover:bg-gray-100 hover:text-gray-900",
			secondary: "bg-gray-100 text-gray-900 hover:bg-opacity-80 hover:bg-gray-100",
			ghost: "text-gray-900 hover:bg-gray-100 hover:text-gray-900",
			naked:
				"text-gray-900 hover:bg-opacity-10 hover:bg-gray-800 active:bg-opacity-10 ative:bg-gray-800",
			link: "text-gray-900 hover:underline",
			transparent: "",
		},
		size: {
			default: "h-10 px-4 py-2",
			auto: "h-auto rounded-sm",
			sm: "h-9 rounded-md px-3",
			lg: "h-11 rounded-md px-8",
			icon: "h-10 w-10 p-2",
		},
		color: {
			default: "",
			legacy:
				"bg-pink text-white hover:bg-pink hover:bg-opacity-90 active:bg-opacity-80 disabled:bg-opacity-60",
		},
	},
	defaultVariants: {
		variant: "default",
		size: "default",
		color: "default",
	},
});
