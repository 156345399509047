






/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from "@vue/composition-api";
import { cardVariants } from "@/lib/pfg/components/card";

export default defineComponent<any, any, any, any, any, any, any, any, any>({
	setup() {
		return { cardVariants };
	},
});
