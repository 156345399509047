






/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from "@vue/composition-api";
import { labelVariants } from "@/lib/pfg/components/label";

export default defineComponent<any, any, any, any, any, any, any, any, any>({
	setup() {
		return { labelVariants };
	},
});
